import IndexableInterface from '@/core/interfaces/IndexableInterface';

const navigationItems: IndexableInterface = [
  {
    title: 'Pulpit',
    icon: 'dashboard',
    route: { name: 'dashboard' },
  },
  {
    title: 'Czytnik QR',
    icon: 'qr_code_scanner',
    route: { name: 'qr-reader' },
  },
  {
    divider: true,
    breakpoint: 'mdAndDown',
  },
  {
    title: 'Wyloguj się',
    icon: 'power_settings_new',
    route: { name: 'auth.logout' },
    breakpoint: 'mdAndDown',
  },
];

export { navigationItems };
