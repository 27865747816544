const authRoutes: any = [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/shared/resources/views/EmptyRouterView.vue'),
    children: [
      {
        path: 'login',
        name: 'auth.login',
        component: () => import('@/app/views/modules/auth/AuthLoginView.vue'),
        meta: {
          layout: 'auth',
          guest: true,
        },
      },
      {
        path: 'logout',
        name: 'auth.logout',
        component: () => import('@/app/views/modules/auth/AuthLogoutView.vue'),
        meta: {
          layout: 'checking',
        },
      },
    ],
  },
];

export { authRoutes };

