import Vue from 'vue';
import Vuelidate from 'vuelidate';

import { router } from '@/app/router';
import { store } from '@/app/store';
import { modules } from '@/app/config/modulesConfig';

import App from '@/app/App.vue';

import { ORMInstall } from '@/core/bridge/orm/support/ORMInstall';
import ServiceProvider from '@/core/providers/ServiceProvider';

import 'sweetalert2/src/sweetalert2.scss';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@/shared/resources/assets/scss/app.scss';
import vuetify from '@/shared/resources/plugins/vuetify';

import Popup from '@/shared/lib/support/popups/Popup';
import Validation from '@/shared/lib/support/validation/Validation';
import Locale from '@/core/locale/Locale';
import GlobalSettings from '@/shared/lib/settings/GlobalSettings';
import StoreSettingsStorageAdapter from '@/core/settings/adapters/StoreSettingsStorageAdapter';
import UISettings from '@/app/lib/settings/UISettings';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';

export default class AppServiceProvider extends ServiceProvider {
  /**
   * Init service provider
   */
  public async init() {
    // VuexOrm store plugin installation
    ORMInstall(modules, store);

    // use available store settings adapter
    GlobalSettings.storage.adapter(new StoreSettingsStorageAdapter('GLOBAL'), true);

    UISettings.storage.adapter(new BrowserLocalSettingsStorageAdapter('UI'), true);
    UISettings.store.adapter(new StoreSettingsStorageAdapter('UI'), true);

    // hide loader
    document.body.classList.remove('app-placeholder');

    // install plugins
    Vue.use(Vuelidate);

    // init libs
    Popup.load(Locale.i18n);
    Validation.load(Locale.i18n);

    // app init
    new Vue({
      i18n: Locale.i18n,
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
}
