import Router, { Route, RouteRecord } from 'vue-router';
import { log } from 'util';

const TRAILING_SLASH: RegExp = /\/?$/;

/**
 * Redirect.
 *
 * @param router
 * @param route
 */
export const redirect = (router: Router, route: any) => {
  return router.push(route).catch((err: any) => null);
};
//
// /**
//  * Add query params to route
//  * @param params
//  */
// export const addQueryParams = async (params: object) => {
//   const route = router.currentRoute;
//
//   await redirect({
//     ...route as object,
//     query: {
//       ...route.query as object,
//       ...params,
//     },
//   });
// };

/**
 * Search in path
 * @param path
 * @param route
 */
export const existsInPath = (path: string, route: Route) => {
  return route.path!.search(path) > -1;
};

/**
 * Check if route is public
 * @param route
 */
export const isPublic = (route: Route) => {
  return route.meta && !!route.meta.public;
};

/**
 * Check if route contains other route
 * @param a
 * @param b
 */
export const routeContains = (a: Route, b: Route): boolean => {
  return a.path
    .replace(TRAILING_SLASH, '/')
    .indexOf(b.path.replace(TRAILING_SLASH, '/')) === 0;
};

/**
 * Get route layout
 * @param route
 */
export const getRouteLayout = (route: Route): string => {  // TODO enum
  return route.meta && route.meta.layout ? route.meta.layout : 'default';
};

/**
 * Check if route is private
 * @param route
 */
export const isRoutePrivate = (route: Route): boolean => {  // TODO enum
  if (!route.meta) {
    return false;
  }

  return !route.meta.public;
};

export const getNearestRouteTitle = (routes: RouteRecord[]): string | null => {
  routes.reverse();

  const test = routes.filter((route: RouteRecord) => {
    return !!route.meta && !!route.meta.title;
  });

  if (!test.length) {
    return null;
  }

  return test[0].meta.title;
};
