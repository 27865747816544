

























import { Component, Vue } from 'vue-property-decorator';

import TopBar from '@/app/layouts/default/top-bar/components/TopBar.vue';

import Navigation from '@/app/layouts/default/navigation/components/Navigation.vue';
import NavigationContent from '@/shared/resources/layouts/navigation/components/NavigationContent.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Badge from '@/shared/resources/components/Badge.vue';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import { navigationItems } from '@/app/layouts/NavigationItems';
import { SideModalInterface } from '@/shared/lib/interfaces/SideModalInterface';
import UISettings from '@/app/lib/settings/UISettings';

const DEFAULT_NAVIGATION_COMPONENT: string = 'NavigationContent';

@Component({
  components: {
    Badge,
    Button,
    Icon,
    TopBar,
    Navigation,
    NavigationContent,
  },
})
export default class DefaultLayout extends Vue {
  /**
   * Data
   */
  private navigationItems: IndexableInterface = navigationItems;

  /**
   * Getters
   */
  private get navigationComponent(): string {
    return DEFAULT_NAVIGATION_COMPONENT;
  }

  private get appendDefaultComponent(): boolean {
    return this.navigationComponent === DEFAULT_NAVIGATION_COMPONENT;
  }

  private get disableRouteWatcher(): boolean {
    return this.navigationComponent !== DEFAULT_NAVIGATION_COMPONENT;
  }

  private get sideModals(): SideModalInterface[] {
    return UISettings.sideModals;
  }

  /**
   * Class names
   */
  private get appClassNames(): object {
    return {
      'default-layout': true,
    };
  }

  /**
   * Handlers
   */
  private handleSideModalHide(index: number) {
    UISettings.closeSideModal(index);
  }

}
