import { AxiosResponse } from 'axios';
import UserCoreModel from '@/modules/users/models/UserCoreModel';
import { default as AuthBase } from '@/core/auth/Auth';
import AppSettings from '@/app/lib/settings/AppSettings';

export default class Auth extends AuthBase {
  /**
   * Init
   */
  public static async init() {
    try {
      const user: UserCoreModel = await UserCoreModel
        .apiExt()
        .baseUrl(this.apiBaseUrl)
        .get();
    } catch (ex) {
      throw ex;
    }
  }

  /**
   * Login callback.
   *
   * @param response
   * @protected
   */
  protected static async loginCallback(response: AxiosResponse) {
    await this.init();
  }

  /**
   * Logout callback.
   *
   * @protected
   */
  protected static async logoutCallback() {
    AppSettings.resetData();
  }
}
