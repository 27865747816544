import SettingsStorage from '@/core/settings/SettingsStorage';
import SettingsStorageRegistry from '@/core/settings/SettingsStorageRegistry';
import BrowserLocalSettingsStorageAdapter from '@/core/settings/adapters/BrowserLocalSettingsStorageAdapter';
import ApiSettings from '@/core/api/settings/ApiSettings';
import UserCoreModel from '@/modules/users/models/UserCoreModel';

export default class AppSettings {
  /**
   * Get user.
   */
  public static get user(): UserCoreModel | null {
    if (!ApiSettings.userId) {
      return null;
    }

    return UserCoreModel
      .query()
      .where('id', ApiSettings.userId)
      .first();
  }

  /**
   * Set storage.
   */
  public static readonly storage: SettingsStorage = SettingsStorageRegistry.register(
    new BrowserLocalSettingsStorageAdapter('APP'),
  );

  /**
   * Reset data.
   */
  public static resetData() {
    //
  }
}
