import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import Locale from '@/core/locale/Locale';

export default class TicketCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'tickets';

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(0),

      name: this.string(''),
      photo: this.string(''),
      price: this.string(0),

      amount: this.number(0),
      sold: this.number(0),
    };
  }

  /**
   * Public fields
   */
  public id!: number;

  public name!: string;
  public photo!: string | null;
  public price!: string;

  public amount!: number;
  public sold!: number;

  /**
   * Check if ticket is sold out.
   */
  public isSoldOut(): boolean {
    return this.amount <= this.sold;
  }

  /**
   * Get price with currency
   */
  public get priceWithCurrency(): string {
    return Locale.priceWithCurrency(parseFloat(this.price));
  }
}
