import ServiceProvider from '@/core/providers/ServiceProvider';
import ApiSchemaV2 from '@/shared/lib/api/schema/ApiSchemaV2';
import ApiSettings from '@/core/api/settings/ApiSettings';
import UISettings from '@/app/lib/settings/UISettings';
import ApiV1Settings from '@/core/api/settings/ApiV1Settings';

export default class SettingsServiceProvider extends ServiceProvider {
  /**
   * Init service provider
   */
  public async init() {
    ApiSettings.apiURL = (process.env.VUE_APP_API_URL as string) + '/tickets/v1';
    ApiSettings.schema = new ApiSchemaV2();
    ApiSettings.schema.apiSettings = ApiSettings;
    ApiSettings.loadCachedData();

    ApiV1Settings.apiURL = process.env.VUE_APP_API_URL as string;
    ApiV1Settings.schema = new ApiSchemaV2();
    ApiV1Settings.schema.apiSettings = ApiV1Settings;

    UISettings.layout = 'checking';
    UISettings.loadCachedSettings();
  }
}
