import { authRoutes } from '@/app/routes/modules/authRoutes';

const appRoutes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "tickets-dashboard" */ '@/app/views/DashboardView.vue'),
    meta: {
      title: 'dashboard',
    },
  },
  {
    path: '/qr-reader',
    name: 'qr-reader',
    component: () => import(/* webpackChunkName: "tickets-qr-reader" */ '@/app/views/QRReaderView.vue'),
    meta: {
      title: 'qr-reader',
    },
  },

  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "tickets-redirect" */ '@/shared/resources/views/RedirectView.vue'),
  },

  ...authRoutes,

  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "tickets-not-found" */ '@/app/views/NotFoundView.vue'),
  },
];

export { appRoutes };
