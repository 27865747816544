import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import Locale from '@/core/locale/Locale';
import { OnlineOrderTotalsFormattedInterface } from '@/modules/online-orders/interfaces/OnlineOrderTotalsInterface';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';
import { PricesEnum } from '@/modules/prices/enums/PricesEnum';
import OnlineOrderStatusCoreModel from '@/modules/online-orders/models/OnlineOrderStatusCoreModel';
import { ApiORMModelRelationsFieldsContract } from '@/core/bridge/orm/api/relations/contracts/ApiORMModelRelationsFieldsContract';
import { ApiRelationModelsEnum } from '@/shared/lib/api/relations/ApiRelationModelsEnum';
import { formatDateTime } from '@/core/helpers/utils/DateUtils';
import { OnlineOrdersStatusesEnum } from '@/modules/online-orders/enums/OnlineOrdersStatusesEnum';
import { PaymentsTypesEnum } from '@/modules/payments/enums/PaymentsTypesEnum';
import { DeliveriesEnum } from '@/modules/deliveries/enums/DeliveriesEnum';
import { PaymentDeliveryInterface, PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';
import OnlineOrderAddressDataCoreModel from '@/modules/online-orders/models/OnlineOrderAddressDataCoreModel';
import { DeliveryInterface } from '@/modules/deliveries/interfaces/DeliveriesInterface';
import OnlineOrderItemCoreModel from '@/modules/online-orders/models/OnlineOrderItemCoreModel';
import Payments from '@/shared/lib/support/Payments';

export default class OnlineOrderCoreModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'online-orders';

  /**
   * API conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/online-orders',
      },
      get: {
        method: 'GET',
        url: '/online-orders/:id',
      },
      create: {
        method: 'POST',
        url: '/online-orders',
      },
      count: {
        method: 'GET',
        url: '/online-orders/count',
      },
    },
  };

  /**
   * Relation fields
   */
  public static relationFields: ApiORMModelRelationsFieldsContract = {
    [ApiRelationModelsEnum.CONTRACTOR]: 'contractor',
    [ApiRelationModelsEnum.ONLINE_ORDER_ITEMS]: 'items',
    [ApiRelationModelsEnum.ONLINE_ORDER_STATUS]: 'statuses',
    [ApiRelationModelsEnum.ONLINE_ORDER_ADDRESS_DATA]: 'addressData',
  };

  /**
   * ORM fields
   */
  public static fields() {
    return {
      id: this.number(0),
      hash: this.string(''),
      order: this.number(0),
      number: this.string(''),

      email: this.string(null).nullable(),
      phone: this.string(null).nullable(),

      addressDataId: this.number(null).nullable(),
      addressData: this.belongsTo(OnlineOrderAddressDataCoreModel, 'addressDataId'),

      invoiceDataId: this.number(null).nullable(),
      contractorId: this.number(null).nullable(),
      stockId: this.number(0),

      items: this.hasMany(OnlineOrderItemCoreModel, 'orderId'),

      statuses: this.hasMany(OnlineOrderStatusCoreModel, 'orderId'),

      prices: this.string(''),
      delivery: this.string(null).nullable(),
      payment: this.string(null).nullable(),
      paymentType: this.string(null).nullable(),
      paymentDelivery: this.attr(null).nullable(),

      productsNet: this.number(0),
      productsGross: this.number(0),
      productsVat: this.number(0),

      deliveryPrice: this.number(0),
      total: this.number(0),

      comments: this.string(null).nullable(),

      createdAt: this.string(''),
      updatedAt: this.string(''),
    };
  }

  /**
   * Public fields
   */
  public id!: number | null;
  public hash!: string;
  public order!: number;
  public number!: string;

  public email!: string | null;
  public phone!: string | null;

  public addressDataId!: number | null;
  public addressData!: OnlineOrderAddressDataCoreModel | null;

  public invoiceDataId!: number | null;
  public contractorId!: number | null;
  public stockId!: number;

  public statuses!: OnlineOrderStatusCoreModel[];

  public items!: OnlineOrderItemCoreModel[];

  public prices!: PricesEnum;
  public delivery!: DeliveriesEnum | null;
  public payment!: PaymentsEnum | null;
  public paymentType!: PaymentsTypesEnum | null;

  public paymentDelivery!: PaymentDeliveryInterface | null;

  public productsNet!: number;
  public productsGross!: number;
  public productsVat!: number;

  public deliveryPrice!: number;
  public total!: number;

  public comments!: string | null;

  public createdAt!: string;
  public updatedAt!: string;

  /**
   * Get totals formatted
   */
  public get totalsFormatted(): OnlineOrderTotalsFormattedInterface {
    return {
      productsTotal: Locale.price(this.productsGross),
      deliveryPrice: Locale.price(this.deliveryPrice),
      total: Locale.price(this.total),
    };
  }

  /**
   * Get totals formatted with currency
   */
  public get totalsFormattedWithCurrency(): OnlineOrderTotalsFormattedInterface {
    return {
      productsTotal: Locale.priceWithCurrency(this.productsGross),
      deliveryPrice: Locale.priceWithCurrency(this.deliveryPrice),
      total: Locale.priceWithCurrency(this.total),
    };
  }

  /**
   * Get date
   */
  public get date(): string {
    return formatDateTime(this.createdAt);
  }

  /**
   * Get status based on last status
   */
  public get status(): OnlineOrdersStatusesEnum {
    return this.lastStatus.status;
  }

  /**
   * Get last status
   */
  public get lastStatus(): OnlineOrderStatusCoreModel {
    return this.statuses[this.statuses.length - 1];
  }

  /**
   * Check if order should be paid
   */
  public get shouldBePaid(): boolean {
    if (this.isFinished || this.isCanceled) {
      return false;
    }

    return !this.isPaid;
  }

  /**
   * Check if order is paid
   */
  public get isPaid(): boolean {
    return this.checkIfHasStatus(OnlineOrdersStatusesEnum.PAYMENT_CONFIRMED);
  }

  /**
   * Check if order is finished
   */
  public get isFinished(): boolean {
    return this.checkIfHasStatus(OnlineOrdersStatusesEnum.FINISHED);
  }

  /**
   * Check if order is canceled
   */
  public get isCanceled(): boolean {
    return this.checkIfHasStatus(OnlineOrdersStatusesEnum.CANCELED);
  }

  /**
   * Check if address is required
   */
  public get requireAddress(): boolean {
    return !!(this.deliveryConfig && this.deliveryConfig.requireAddress);
  }

  /**
   * Check if payment is required
   */
  public get requirePayment(): boolean {
    return !!(this.paymentTypeConfig && this.paymentTypeConfig.requirePayment);
  }

  /**
   * Check if has free delivery
   */
  public get hasFreeDelivery(): boolean {
    return this.deliveryPrice === 0;
  }

  /**
   * Get delivery config
   */
  public get deliveryConfig(): DeliveryInterface | null {
    if (!this.paymentType || !this.delivery) {
      return null;
    }

    return Payments.getDelivery(this.paymentType, this.delivery);
  }

  /**
   * Get payment type config
   */
  public get paymentTypeConfig(): PaymentsTypeInterface | null {
    if (!this.paymentType) {
      return null;
    }

    return Payments.getPaymentsType(this.paymentType);
  }

  /**
   * Sync contact data
   * @param email
   * @param phone
   */
  public syncContactData(email: string | null, phone: string | null) {
    this.email = email;
    this.phone = phone;
  }

  /**
   * Sync address data
   * @param name
   * @param street
   * @param building
   * @param locale
   * @param zipCode
   * @param place
   */
  public syncAddressData(
    name: string,
    street: string,
    building: string,
    locale: string | null,
    zipCode: string,
    place: string,
  ) {
    if (!this.addressData) {
      this.addressData = new OnlineOrderAddressDataCoreModel();
    }

    this.addressData.syncData(name, street, building, locale, zipCode, place);
  }

  /**
   * Convert to object
   */
  public toObject() {
    return {
      hash: this.hash,
      contractorId: this.contractorId,
      email: this.email,
      phone: this.phone,
      prices: this.prices,
      paymentType: this.paymentType,
      payment: this.payment,
      delivery: this.delivery,
      addressDataId: this.addressData?.id,
      addressData: this.addressData?.toObject(),
      comments: this.comments,
    };
  }

  /**
   * Prepare data to create request
   */
  public toCreateRequest(): object | null {
    return {
      email: this.email,
      phone: this.phone,

      address: this.addressData ? this.addressData.toRequest() : null,

      payment: this.payment,
      delivery: this.delivery,

      comments: this.comments,
    };
  }

  /**
   * Check if order has given status
   * @param statusToCheck
   * @private
   */
  private checkIfHasStatus(statusToCheck: OnlineOrdersStatusesEnum) {
    return this.statuses.some((status: OnlineOrderStatusCoreModel) => {
      return status.status === statusToCheck;
    });
  }
}
