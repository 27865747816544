// core modules
import StoreSettingsStorageModel from '@/core/settings/models/StoreSettingsStorageModel';
import FileCoreModel from '@/modules/files/models/FileCoreModel';

// app modules
import UserCoreModel from '@/modules/users/models/UserCoreModel'; // TODO
import TicketModel from '@/app/modules/tickets/models/TicketModel';
import QRCodeModel from '@/app/modules/qr-codes/models/QRCodeModel';

const modules: any[] = [
  // core modules
  StoreSettingsStorageModel,
  FileCoreModel,

  // app modules
  UserCoreModel,
  TicketModel,
  QRCodeModel,
];

export { modules };
