import TicketCoreModel from '@/modules/tickets/models/TicketCoreModel';

export default class TicketModel extends TicketCoreModel {
  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      fetch: {
        method: 'GET',
        url: '/tickets',
      },
    },
  };
}
